body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
  height: 100vh;
}

@font-face {
  font-family: 'BrandonGrotesque-Medium';
  src: local('BrandonGrotesque-Medium'), url('./styles/fonts/BrandonGrotesque-Medium.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: local('BrandonGrotesque-Bold'), url('./styles/fonts/BrandonGrotesque-Bold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: local('BrandonGrotesque-Regular'), url('./styles/fonts/BrandonGrotesque-Regular.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'BrandonGrotesque-Thin';
  src: local('BrandonGrotesque-Thin'), url('./styles/fonts/BrandonGrotesque-Thin.woff') format('woff');
  font-style: normal;
}
